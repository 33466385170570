import { DataScope } from './global-enum';
import {
  ApiRequestParams,
  defaultApiRequestParams,
} from './global-interfaces';

export const locationOrderApiRequestParams: ApiRequestParams = {
  ...defaultApiRequestParams,
  dataScope: DataScope.LocationTable,
};

export interface LocationRequestParams extends ApiRequestParams {
  assignedLocations?: number;
}

export interface CreateLocationParams {
  locationName: string;
  dueAt?: string;
  reoccurAt?: string;
  locationSchedulerActive?: number;
  timezoneID?: number;
}

export const assignedLocationApiRequestParams: LocationRequestParams = {
  ...defaultApiRequestParams,
  assignedLocations: 1,
};

export interface TaskBoardUser {
  created_at: string;
  customerID: number;
  deleted_at: null;
  isAzureUser: number;
  isLocationDefaultUser: number;
  notifyEmailAssignmentGroup: number;
  notifyEmailAssignmentUser: number;
  notifyEmailOther: number;
  notifyEmailReminder: number;
  updated_at: string;
  userActive: number;
  userApproved: number;
  userDefaultLocationID: number;
  userEmail?: string;
  userFirstName: string;
  userFullName: string;
  userID: number;
  userLastName: string;
  userLoginAttempt?: string;
  userLoginName: string;
  userLogins: number;
  userPhone?: string;
  userType?: string;
}
