import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskBoardUser } from '@models/location.model';
import { CreateLocationParams } from '@models/location.model';
import {
  ApiRequestParams,
  ApiResponseArray,
  ApiResponseMessage,
} from '@root/models/global-interfaces';
import { isArray } from 'lodash';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { removeEntity, setAllEntities, withEntities } from '@ngrx/signals/entities';
import {
  setError,
  setComplete,
  withRequestStatus,
  setPending,
} from './features/request-status.feature';
import { LocationService } from '@services/location.service';
import { GeneralStore } from './general.store';

export interface LocationPreview {
  id: number;
  customerID: number;
  locationDefaultUser: number;
  locationID: number;
  locationName: string;
  locationSchedulerActive: number;
  order: number;
  taskBoardUser: string;
  isDeployed: boolean;
}

export interface previousLocation {
  previousLocationId: number;
  previousLocationName: string;
  previousLocationUrl: string;
}
export interface LocationMinified {
  locationID: number;
  locationName: string;
  isDeployed: boolean;
}

export interface LocationDetails {
  id: number;
  created_at: Date | undefined;
  customerID: number | undefined;
  dueAt: string;
  reoccurAt: string;
  locationDefaultUser: number | string | undefined;
  locationID: number;
  locationName: string | undefined;
  locationSchedulerActive: number | undefined;
  task_board_user: TaskBoardUser | undefined;
  timezoneID: number | undefined;
  isSelected?: boolean;
  order?: number;
  taskBoardUser?: TaskBoardUser;
  timezones?: Timezone[];
  previousLocationId?: number;
  previousLocationName?: string;
  previousLocationUrl?: string;
}

export interface AssignedLocation {
  created_at: Date | undefined;
  customerID: number | undefined;
  deleted_at: Date | undefined;
  dueAt: string;
  locationDefaultUser: number | undefined;
  locationID: number;
  locationName: string | undefined;
  locationSchedulerActive: boolean;
  order?: number;
  reoccurAt: string;
  task_board_user: TaskBoardUser | undefined;
  timezoneID: number | undefined;
  updated_at: Date | undefined;
}

export interface Timezone {
  gmtOffset: string;
  name: string;
  timezoneID: number;
  abbreviation: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface LocationState {
  currentLocationId: number;
  previousLocationId: number;
  previousLocationName: string;
  previousLocationUrl: string;
  loaded: boolean;
  currentLocation: LocationPreview;
}

/* eslint-disable @typescript-eslint/typedef */
export const LocationsStore = signalStore(
  { providedIn: 'root' },
  withEntities<LocationPreview>(),
  withState<LocationState>({
    currentLocationId: null,
    previousLocationId: null,
    previousLocationName: null,
    previousLocationUrl: null,
    loaded: false,
    currentLocation: null,
  }),
  withRequestStatus(),
  withMethods(
    (
      store,
      generalStore = inject(GeneralStore),
      locationsService: LocationService = inject(LocationService),
    ) => ({
      createLocation(
        /* eslint-disable @typescript-eslint/no-explicit-any */
        params: CreateLocationParams,
        callback?: any,
      ): Observable<ApiResponseArray<LocationDetails>> {
        return locationsService.createLocation(params).pipe(
          tapResponse({
            next: (_res: ApiResponseArray<LocationDetails>) => {
              // If we can get the full details in the response then we could use addEntity
              this.getEntityData();
              generalStore.getCurrentUser(true);
              if (callback) {
                callback();
              }
            },
            error: (error: { message: string }) => {
              patchState(store, setError(error.message));
            },
          }),
        );
      },
      deleteLocation(locationID: number): Observable<ApiResponseMessage> {
        return locationsService.deleteLocation(locationID).pipe(
          tapResponse({
            next: (_res: ApiResponseMessage) => {
              patchState(store, removeEntity(locationID));
              this.getEntityData();
              generalStore.getCurrentUser(true);
            },
            error: (error: { message: string }) => {
              patchState(store, setError(error.message));
            },
          }),
        );
      },
      updateLocation(
        location: { locationID: number } & Partial<LocationDetails>,
      ): Observable<ApiResponseMessage> {
        return locationsService.updateLocation(location).pipe(
          tapResponse({
            next: (_res: ApiResponseMessage) => {
              this.getEntityData();
              generalStore.getCurrentUser(true);
            },
            error: (error: { message: string }) => {
              patchState(store, setError(error.message));
            },
          }),
        );
      },
      updateLocationOrder(
        locations: LocationPreview[] | undefined,
      ): Observable<ApiResponseMessage> {
        return locationsService.updateLocationOrder(locations).pipe(
          tapResponse({
            next: (_res: ApiResponseMessage) => {
              this.getEntityData();
              generalStore.getCurrentUser(true);
            },
            error: (error: { message: string }) => {
              patchState(store, setError(error.message));
            },
          }),
        );
      },
      getEntityData(params?: ApiRequestParams): void {
        setPending();
        locationsService
          .getLocations(params)
          .pipe(
            tapResponse({
              next: (res: ApiResponseArray<LocationPreview>) => {
                let locations: LocationPreview[] = [] as LocationPreview[];
                if (isArray(res)) {
                  locations = res as LocationPreview[];
                }
                patchState(store, setAllEntities(locations));
                patchState(store, setComplete());
              },
              error: (error: { message: string }) => {
                patchState(store, setError(error.message));
              },
            }),
          )
          .subscribe();
      },
      setCurrentLocationId(currentLocationId: number) {
        if (currentLocationId) {
          const currentLocation = store.entities().find((location: LocationPreview) => {
            return location.locationID === currentLocationId;
          });
          patchState(store, { currentLocation: currentLocation });
        }
        else {
          patchState(store, { currentLocation: null });
        }
        patchState(store, { currentLocationId: currentLocationId });
      },
      setPreviousLocation(location: previousLocation) {
        patchState(store, {
          previousLocationId: location.previousLocationId,
          previousLocationName: location.previousLocationName,
          previousLocationUrl: location.previousLocationUrl,
          loaded: true
        });
      },
    }),
  )
);
