import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { locationOrderApiRequestParams } from '@models/location.model';
import {
  CreateLocationParams,
  LocationRequestParams,
} from '@models/location.model';
import { createHttpParams } from './utilities';
import {
  ApiDataResponseArray,
  ApiDataResponseObject,
  ApiResponseArray,
  ApiResponseMessage,
} from '@root/models/global-interfaces';
import { isArray } from 'lodash';
import {
  LocationDetails,
  LocationPreview,
  Timezone,
} from '@store/location.store';

export interface LocationTaskCount {
  count: number;
  color: number;
}

@Injectable()

export class LocationService {
  public apiUrl = environment.apiUrl;
  private http: HttpClient = inject(HttpClient);

  public readonly getLocations = (
    req?: LocationRequestParams,
  ): Observable<ApiResponseArray<LocationPreview>> => {
    /* eslint-disable @typescript-eslint/typedef */
    const params = req ?? locationOrderApiRequestParams;
    return this.http
      .get<LocationPreview[]>(`${this.apiUrl}locations`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: LocationPreview[]) => {
          return res as ApiResponseArray<LocationPreview>;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public readonly getLocationById = (
    locationID: number,
    dataScope?: string,
  ): Observable<ApiDataResponseObject<LocationDetails> | ApiResponseArray<LocationDetails>> => {
    return this.http
      .get(`${this.apiUrl}locations/${locationID}`, {
        params: createHttpParams({ dataScope }),
      })
      .pipe(
        map((res: ApiResponseArray<LocationDetails>) =>
          isArray(res.data) ? { ...res, data: res.data[0] } : res,
        ),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public readonly createLocation = (
    params: CreateLocationParams,
  ): Observable<ApiResponseArray<LocationDetails> | ApiResponseMessage> => {
    return this.http.post(`${this.apiUrl}locations`, { ...params }).pipe(
      map((res: ApiResponseMessage) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public readonly deleteLocation = (
    locationID: number,
  ): Observable<ApiResponseMessage> => {
    return this.http.delete(`${this.apiUrl}locations/${locationID}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public readonly updateLocation = (
    location: { locationID: number } & Partial<LocationDetails>,
  ): Observable<ApiResponseMessage> => {
    return this.http
      .put(`${this.apiUrl}locations/${location.locationID}`, { ...location })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public readonly updateLocationOrder = (
    locations: LocationPreview[] | undefined,
  ): Observable<ApiResponseMessage> => {
    return this.http
      .put(`${this.apiUrl}locations/update-location-order`, { locations })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public readonly getTimezones = (): Observable<
    ApiDataResponseArray<Timezone>
  > => {
    return this.http.get(`${this.apiUrl}timezone`).pipe(
      map((res: ApiDataResponseArray<Timezone>) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => {}),
    );
  };

  public getLocationTaskCounts(locationID: number): Observable<LocationTaskCount> {
    return this.http
      .get(`${this.apiUrl}tasks/${locationID}/my-task-count`)
      .pipe(
        map((res: LocationTaskCount) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  }
}
